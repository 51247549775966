<template>
	<a-card :class="['card-credit', 'header-solid', 'h-full']" :style="cardStyle">
	  <template #title>
		<h10 class="card-number">{{ cardNumber }}</h10>
	  </template>
	  
	  <div class="card-footer">
		<!-- Additional content here if needed -->
	  </div>
	</a-card>
  </template>
  
  <script>
  export default {
	data() {
	  return {
		cardNumber: "NL18 RABO 0123 4598 76",
		accountOwner: "",
		expirationDate: "",
	  };
	},
	computed: {
	  cardStyle() {
		// Check if the card number contains "RABO" or "ABNA"
		if (this.cardNumber.includes("RABO")) {
		  return {
			backgroundImage: "url('https://media.rabobank.com/m/79817688ea6974af/original/gezamenlijk-betaalpas__no_mc.png')",
		  };
		} else if (this.cardNumber.includes("ABNA")) {
		  return {
			backgroundImage: "url('https://www.bank.nl/wp-content/uploads/2023/05/bankpas-abn-min.png')",
		  };
		}
		return {};
	  },
	},
  };
  </script>
  
  <style scoped>
  .card-credit {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	max-width: 400px;
	margin: auto;
  }
  
  .card-number {
	position: absolute;
	top: 79%;
	left: 10%;
	font-weight: bold;
	white-space: nowrap;
  }
  </style>
  